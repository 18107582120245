import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-clubes',
  templateUrl: './clubes.component.html',
  styleUrls: ['./clubes.component.scss']
})
export class ClubesComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public isAuthLoading = false;

  public user: any = null;
  reporteExport:any;
  findInput: any;
  obj: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;
  public loadingImg = false;

  objTipoCurso: any = [];
  selectItem: any = [];
  valorContrato:any;


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    // console.log(this.user);
    this.getList();  
    
    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.required),
      logo: new FormControl(null, Validators.nullValidator),
      logo_url: new FormControl(null, Validators.nullValidator),
    });   
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.required),
      logo: new FormControl(null, Validators.nullValidator),
      logo_url: new FormControl(null, Validators.nullValidator),
    });       

  }

  getList(){   
    this.cargando = true;
    this.appService.getClub(this.user.et+'-'+this.page).subscribe( (data) => {
    this.obj = data['obj']; 
    // this.ForpagesNumber();
    this.cargando = false;
    });
  }



  changePage(page) {
    this.page = page; //para el filtro
    this.obj.current = page;
    this.getList();
  }
  
  ForpagesNumber() {
    let from = this.obj.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.obj.total_page){
      to = this.obj.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async new() {     
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              et: this.user.et,
              campos: this.nuevoForm.value,
              estado: 1,
            };
            // console.log(params);
            this.appService.postClub(params)
          .subscribe( (data) => {
            // console.log(data);
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  $('#nuevo').modal('hide');
                  // $('#offcanvasNuevaUsuario').offcanvas('hide');
                  this.toastr.success(data["msg"]);
                  this.getList();
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }

  ver(item){
    // console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('detalle').setValue(item.detalle);
    this.editForm.get('logo').setValue(item.logo);
    this.editForm.get('logo_url').setValue(item.logo_url);
    $('#ver').modal('show');
  }

  async edit(){
    if (this.editForm.valid) {
      this.isAuthLoading = true; 
        let params = {
            editar:true,
            id: this.editForm.value.id,
            campos: this.editForm.value,
          };
          // console.log(params);
          this.appService.postClub(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.toastr.success(data["msg"]);
              this.editForm.reset()
              $('#ver').modal('hide');
              this.getList();
            }else{
              this.toastr.error(data["msg"]);
            }
        }); 
      this.isAuthLoading = false;
  } else {
      this.toastr.error('El formulario no es válido!');
  }
  }

  async eliminarUsuario(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Desea eliminar la empresa ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');
        
        let params = {
          eliminar : true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postClub(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.getList();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });  

  }

  async findBuscar() {     
    let params = {
        buscar:true,
        buscar_input: this.findInput,
        page: this.page,
        et: this.user.et,
      };
        this.appService.postClub(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.obj = data['obj']; 
            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }

 

  uploadImg(files,componente) {
    if (files.length === 0)
      return;   
    this.loadingImg =true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      const imgURL = reader.result; 
    //   console.log("esta es la imagen"+imgURL);

      if(!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'firmas',
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
        .subscribe( (data) => {
          if(data['logger'] == true){
            if(componente =='nuevo'){
              console.log('nuevo');
              
              this.nuevoForm.get('firma').setValue(data['filename']);
              this.nuevoForm.get('firma_url').setValue(data['fileUrl']);  
            }
            if(componente =='editar'){
              console.log('edit');

              this.editForm.get('firma').setValue(data['filename']);
              this.editForm.get('firma_url').setValue(data['fileUrl']);                
            } 
                                    
          }
          this.loadingImg =false;
        });
      }      
    }

  }  



}
