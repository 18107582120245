import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user;
    public menu = MENU;

    constructor(public appService: AppService) {}

    ngOnInit() {
        this.user = this.appService.user;   
        // console.log(this.user);        
    }
}

export const MENU = [
    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '15kwqgk3123'},
    { name: 'Empresas',path: ['/empresas'], icon: 'fa-city', perfil: '15kwqgk3123'},
    // ADMIN
    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt',perfil: '2fk6i2o3143'},    
    { name: 'Paquetes',path: ['/paquetes'], icon: 'fa-box',  perfil: '2fk6i2o3143'},
    { name: 'Clubes',path: ['/clubes'], icon: 'fa-layer-group',  perfil: '2fk6i2o3143'},
    // { name: 'Cursos',path: ['/cursos'], icon: 'fa-clipboard', perfil: '2fk6i2o3143'},
    // { name: 'Instructores',path: ['/instructores'], icon: 'fa-user-graduate', perfil: '2fk6i2o3143'},
    { name: 'Informes',path: ['/informes'], icon: 'fa-chart-bar', perfil: '2fk6i2o3143'},
    // { name: 'Tipo Curso',path: ['/tipo-curso'], icon: 'fa-check', perfil: '2fk6i2o3143'},
    // { name: 'Empresas',path: ['/instituto'], icon: 'fa-building', perfil: '2fk6i2o3143'},    
    { name: 'Usuarios',path: ['/usuarios'], icon: 'fa-users',  perfil: '2fk6i2o3143'},
    // LOGISTICA  - JUGADOR
    { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt', perfil: '3af3s12f153'},
    // { name: 'Clientes',path: ['/clientes'], icon: 'fa-user-friends',  perfil: '3af3s12f153'},
    // { name: 'Cursos',path: ['/cursos'], icon: 'fa-clipboard', perfil: '3af3s12f153'},
    // { name: 'Instructores',path: ['/instructores'], icon: 'fa-user-graduate', perfil: '3af3s12f153'},
    // { name: 'Tipo Curso',path: ['/tipo-curso'], icon: 'fa-check', perfil: '3af3s12f153'},
    // { name: 'Empresas',path: ['/instituto'], icon: 'fa-building', perfil: '3af3s12f153'},    
    // EMPRESA INSITUTO
    // { name: 'Operaciones',path: ['/'], icon: 'fa-tachometer-alt', perfil: '4af4s34f354'},
    // { name: 'Cursos',path: ['/cursos/empresa'], icon: 'fa-clipboard', perfil: '4af4s34f354'},
    // { name: 'Cursos',path: ['/cursos'], icon: 'fa-clipboard', perfil: '4af4s34f354'},
    // { name: 'Main Menu', icon: 'fa-list-ul',
    //     children: [
    //         {
    //             name: 'Sub Menu',
    //             path: ['/sub-menu-1']
    //         },

    //         {
    //             name: 'Blank',
    //             path: ['/sub-menu-2']
    //         }
    //     ]
    // }
];
