<div class="alert alert-danger" role="alert" *ngIf="user.emant">
    <b>Servicio Temporalmente Suspendido.</b>  <br>Recuerde las fechas limites para el pago de su facturación y evitar contratiempos en los procesos.  Cualquier duda contáctenos.
</div>

<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Bienvenido <small>{{user.nombre | titlecase}}</small></h1>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-6" *ngIf="user.perfil =='15kwqgk3123'">
                <div class="small-box bg-info" [routerLink]="['/empresas']">
                    <div class="inner">
                        <h3 *ngIf="estadisticas.countEmpresa">{{estadisticas.countEmpresa}}</h3>
                        <h3 *ngIf="!estadisticas.countEmpresa">0</h3>
                        <p>Empresas</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-building"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>


            <div class="col-lg-4 col-6" *ngIf="user.perfil =='2fk6i2o3143'" style="cursor: pointer;"
                [routerLink]="['/usuarios']">
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3 *ngIf="estadisticas.count_diamand_all">{{estadisticas.count_diamand_all}}</h3>
                        <h3 *ngIf="!estadisticas.count_diamand_all">0</h3>
                        <p>Diamantes </p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-gem"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-4 col-6" *ngIf="user.perfil =='2fk6i2o3143'" style="cursor: pointer;"
                [routerLink]="['/usuarios']">
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3 *ngIf="estadisticas.count_coin_real_all">{{estadisticas.count_coin_real_all}}</h3>
                        <h3 *ngIf="!estadisticas.count_coin_real_all">0</h3>
                        <p>Monedas Reales </p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-coins"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-4 col-6" *ngIf="user.perfil =='2fk6i2o3143'" style="cursor: pointer;"
                [routerLink]="['/usuarios']">
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3 *ngIf="estadisticas.count_coin_fake_all">{{estadisticas.count_coin_fake_all}}</h3>
                        <h3 *ngIf="!estadisticas.count_coin_fake_all">0</h3>
                        <p>Monedas Falsas </p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-coins"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-4 col-6" *ngIf="user.perfil =='2fk6i2o3143'" style="cursor: pointer;"
                [routerLink]="['/usuarios']">
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3 *ngIf="estadisticas.count_clubs_all">{{estadisticas.count_clubs_all}}</h3>
                        <h3 *ngIf="!estadisticas.count_clubs_all">0</h3>
                        <p>Clubes </p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-layer-group"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-8 col-12" *ngIf="user.perfil =='2fk6i2o3143'" style="cursor: pointer;"
                [routerLink]="['/usuarios']">
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3 *ngIf="estadisticas.count_usuarios_all">{{estadisticas.count_usuarios_all}}</h3>
                        <h3 *ngIf="!estadisticas.count_usuarios_all">0</h3>
                        <p>Usuarios </p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-user-check"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>


            <div class="col-lg-4 col-6" *ngIf="user.perfil =='2fk6i2o3143' || user.perfil =='3af3s12f153'"
                style="cursor: pointer;">
                <div class="small-box bg-body">
                    <div class="inner">
                        <h4><a href="#" target="_blank">Descarga la App</a></h4>
                        <p>Android </p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-mobile"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-6" *ngIf="user.perfil =='2fk6i2o3143' || user.perfil =='3af3s12f153'"
                style="cursor: pointer;" >
                <div class="small-box bg-body">
                    <div class="inner">
                        <h4><a [routerLink]="['/seguimiento/']+user.esigla" target="_blank">Link consulta</a></h4>
                        <p>Certificados</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-link"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div> -->
            <div class="col-lg-4 col-6" *ngIf="user.perfil =='2fk6i2o3143' || user.perfil =='3af3s12f153'"
                style="cursor: pointer;" [routerLink]="['/profile/']">
                <div class="small-box bg-body">
                    <div class="inner">
                        <h4>Mi Perfil</h4>
                        <p>{{user.nombre | titlecase}}</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>


            <div class="col-lg-12" *ngIf="user.perfil =='4af4s34f354'">
                <div class="card text-center">
                    <!-- <img src="/assets/img/banner-certificado.jpg" class="img-fluid"> -->
                    <div class="card-body">
                        <div class="text-center">Certificados expedidos para la empresa <b>{{user.inst_name | titlecase}}</b></div>
                        <p class="card-text">puedes consultar los cursos dictados para cada uno de los empleados</p>
                        <button type="button" class="btn btn-primary" [routerLink]="['/cursos/empresa']">Ir a cursos</button>
                    </div>
                </div>
            </div>

            


        </div>
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->