
<!-- Sidebar -->
<div class="sidebar">
    <span *ngIf="user.elogo">
        <img class="mt-3 img-fluid" [src]="user.elogo" />
    </span>
    <span *ngIf="!user.elogo">
        <img class="mt-3 img-fluid" src="assets/img/logo-bet365park.svg" />
    </span>
    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of menu"
                [menuItem]="item"
                [perfilId]="user.perfil"
            ></app-menu-item>
        </ul>
    </nav>

</div>
