import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public isAuthLoading = false;


  public user: any = null;
  usuarios: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;
  findInputInstituto: any;
  objInstituto: any = [];


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;    
    
    this.getUsuarios();  
    
    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
      perfil_id: new FormControl(null, Validators.required),

      instituto_id: new FormControl(null, Validators.nullValidator),
      instituto_nombre: new FormControl(null, Validators.nullValidator),
      instituto_representante: new FormControl(null, Validators.nullValidator),
      instituto_nit: new FormControl(null, Validators.nullValidator),
    });   
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      fotografia: new FormControl(null, Validators.nullValidator),
      fotografia_url: new FormControl(null, Validators.nullValidator),      
      nombre: new FormControl(null, Validators.required),
      email: new FormControl({value:"", disabled: true}),
      // email: new FormControl(null, Validators.nullValidator),
      celular: new FormControl(null, Validators.nullValidator),
      password: new FormControl(null, Validators.required),
      perfil_id: new FormControl(null, Validators.required),

      coin_fake: new FormControl(null, Validators.nullValidator),
      coin_real: new FormControl(null, Validators.nullValidator),
      diamand: new FormControl(null, Validators.nullValidator),
      estado: new FormControl(null, Validators.required),
    });       

  }

  getUsuarios(){    
    this.cargando = true;
    this.appService.getUsuarios(this.user.perfil+'-'+this.user.et+'-'+this.page).subscribe( (data) => {
    this.usuarios = data['usuarios']; 
    this.ForpagesNumber();
    this.cargando = false;
    });
  }


 

  changePage(page) {
    this.page = page; //para el filtro
    this.usuarios.current = page;
    this.getUsuarios();
  }
  
  ForpagesNumber() {
    let from = this.usuarios.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.usuarios.total_page){
      to = this.usuarios.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }


  async newUsuario() {     
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              campos: this.nuevoForm.value,
              et: this.user.et
            };
            // console.log(params);
            this.appService.postUsuario(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  $('#nuevaUsuario').modal('hide');
                  this.getUsuarios();
                  this.toastr.success(data["msg"]);
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }

  verUsuario(item){
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('fotografia').setValue(item.fotografia);
    this.editForm.get('fotografia_url').setValue(item.fotografia_url);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('email').setValue(item.email);
    this.editForm.get('password').setValue(item.password);
    this.editForm.get('perfil_id').setValue(item.perfil_id);
    this.editForm.get('celular').setValue(item.celular);

    this.editForm.get('coin_fake').setValue(item.coin_fake);
    this.editForm.get('coin_real').setValue(item.coin_real);
    this.editForm.get('diamand').setValue(item.diamand);

    this.editForm.get('estado').setValue(item.estado);

  }

  async editUsuario(){    
    if (this.editForm.valid) {
      this.isAuthLoading = true; 
        let params = {
            editar:true,
            id: this.editForm.value.id,
            campos: this.editForm.value,
            estado: 1,
            et: this.user.et
          };
          // console.log(params);
          this.appService.postUsuario(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.editForm.reset()
                $('#verUsuario').modal('hide');
                this.getUsuarios();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        }); 
      this.isAuthLoading = false;
  } else {
      this.toastr.error('El formulario no es válido!');
  }
  }

  async eliminarUsuario(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar al usuario?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {        
        let params = {
          eliminar : true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postUsuario(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.getUsuarios();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });  

  }

  selectEmpresa(item,tipo){    
    if(tipo =='nuevo'){
      this.nuevoForm.get('instituto_id').setValue(item.id);
      this.nuevoForm.get('instituto_nombre').setValue(item.nombre);
      this.nuevoForm.get('instituto_representante').setValue(item.representante);
      this.nuevoForm.get('instituto_nit').setValue(item.nit);
      $('#nuevaUsuario').modal('show');
      $('#institutoModal').modal('hide');
    }
    if(tipo =='editar'){
      this.editForm.get('instituto_id').setValue(item.id);
      this.editForm.get('instituto_nombre').setValue(item.nombre);
      this.editForm.get('instituto_representante').setValue(item.representante);
      this.editForm.get('instituto_nit').setValue(item.nit);
      $('#verUsuario').modal('show');
      $('#institutoEditModal').modal('hide');
    }
  }

  async findInstituto() {     
    let params = {
        buscar:true,
        buscar_input: this.findInputInstituto,
        page: this.page,
        et: this.user.et,
      };
        this.appService.postInstituto(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.objInstituto = data['obj']; 
            }else{
              this.toastr.error(data["msg"]);
            }
        });
  }

}
