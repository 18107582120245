import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { EmpresasComponent } from '@pages/empresas/empresas.component';
import { UsuariosComponent } from '@pages/usuarios/usuarios.component';
import { FormSolicitudComponent } from '@modules/form-solicitud/form-solicitud.component';
import { ClientesComponent } from '@pages/clientes/clientes.component';
import { InstructoresComponent } from '@pages/instructores/instructores.component';
import { CursosComponent } from '@pages/cursos/cursos.component';
import { TipoCursoComponent } from '@pages/tipo-curso/tipo-curso.component';
import { InstitutoComponent } from '@pages/instituto/instituto.component';
import { NuevoCursoComponent } from '@pages/cursos/nuevo-curso/nuevo-curso.component';
import { VerCursoComponent } from '@pages/cursos/ver-curso/ver-curso.component';
import { SeguimientoComponent } from '@pages/seguimiento/seguimiento.component';
import { PendientesComponent } from '@pages/certificados/pendientes/pendientes.component';
import { ListadoInformeComponent } from '@pages/informes/listado-informe/listado-informe.component';
import { EmpresaCursoComponent } from '@pages/cursos/empresa-curso/empresa-curso.component';
import { DescargarComponent } from '@pages/certificados/descargar/descargar.component';
import { CertificadosInformeComponent } from '@pages/informes/certificados-informe/certificados-informe.component';
import { ClubesComponent } from '@pages/clubes/clubes.component';
import { PaquetesComponent } from '@pages/paquetes/paquetes.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: 'empresas', component: EmpresasComponent },
            { path: 'clubes', component: ClubesComponent },
            { path: 'informes', component: ListadoInformeComponent },
            { path: 'informes/certificado-informe', component: CertificadosInformeComponent },
            { path: 'paquetes', component: PaquetesComponent },
            { path: 'profile', component: ProfileComponent },
            { path: 'blank', component: BlankComponent },
            { path: '', component: DashboardComponent},
            { path: 'usuarios',component: UsuariosComponent},
        ]
    },
    
    { path: 'login',component: LoginComponent,canActivate: [NonAuthGuard]},
    { path: 'register',component: RegisterComponent,canActivate: [NonAuthGuard]},
    { path: 'forgot-password',component: ForgotPasswordComponent,canActivate: [NonAuthGuard]},
    { path: 'recover-password',component: RecoverPasswordComponent,canActivate: [NonAuthGuard]},
    { path: 'privacy-policy',component: PrivacyPolicyComponent,canActivate: [NonAuthGuard]},
    { path: 'seguimiento/:sigla',component: SeguimientoComponent,},
    { path: 'd/:tipo/:token',component: DescargarComponent,},
    // { path: 'solicitud/:sigla',component: FormSolicitudComponent,canActivate: [NonAuthGuard]},
    {path: '**', redirectTo: ''}
    // {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
